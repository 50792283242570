import { HStack } from '@chakra-ui/react';

export const SignUp = () => {
  return (
    <HStack
      minH="100vh"
      width="100%"
      margin="0px auto"
      textAlign="center"
      justifyContent="center"
      fontSize="xl"
      p="20px 15px"
    ></HStack>
  );
};
