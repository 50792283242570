import { Box, HStack, Heading, Progress, Text } from '@chakra-ui/react';
import { ScoreBreakdown } from './ScoreBreakdown';
import { WordReplacements } from './WordReplacements';
import { VocabularySuggestions } from './VocabularySuggestions';
import { AlternateApproach } from './AlternateApproach';
import { DetailedFeedback } from './DetailedFeedback';
import { SubmittedAnswer } from './SubmittedAnswer';
import {
  useGetWritingReportQuery,
  useGetWritingReportStatusQuery
} from '../writingReportApi';
import { useWritingReportContext } from '../hooks/useWritingReportContext';
import { ReportNotEvaluated } from './ReportNotEvaluated';
import { Loading } from '../../common/Loading';

export const WritingReportView = () => {
  const { reportId } = useWritingReportContext();
  const {
    data: writingReportStatusResult,
    isLoading: isEvaluationStatusLoading
  } = useGetWritingReportStatusQuery(
    { reportId },
    {
      skip: !reportId
    }
  );
  const isEvaluated = writingReportStatusResult?.isEvaluated;

  const { data: writingReportResult, isLoading: isWritingReportLoading } =
    useGetWritingReportQuery(
      { reportId },
      {
        skip: !reportId
      }
    );
  if (!isEvaluated && !isEvaluationStatusLoading) {
    return <ReportNotEvaluated />;
  }

  if (isWritingReportLoading) {
    return <Loading />;
  }

  return (
    <Box padding="20px 10%">
      <Heading as="h1">IELTS Writing {writingReportResult?.taskType}</Heading>
      <Text fontWeight="extrabold" fontSize="xl" marginTop="30px">
        <b>Overall Band Score</b>
      </Text>
      <HStack justifyContent="space-between" marginTop="20px">
        <Text fontWeight="bold">You are almost there! Aim for 7.0</Text>
        <Text
          padding="6px 10px"
          borderRadius="5px"
          bg="brand.background"
          fontSize="lg"
          fontWeight="bold"
        >
          {writingReportResult?.overallScore}
        </Text>
      </HStack>
      <Progress
        marginTop="10px"
        borderRadius="20px"
        colorScheme="purple"
        size="sm"
        value={80}
      />
      <Box marginTop="30px">
        <SubmittedAnswer answer={writingReportResult?.answer} />
      </Box>
      <Box>
        <ScoreBreakdown
          taskScore={writingReportResult?.taskScore}
          cohesionScore={writingReportResult?.cohesionScore}
          lexicalScore={writingReportResult?.lexicalScore}
          grammaticalScore={writingReportResult?.grammaticalScore}
        />
      </Box>
      <Box marginTop="30px">
        <DetailedFeedback />
      </Box>
      <Box marginTop="30px">
        <WordReplacements />
      </Box>
      <Box marginTop="30px">
        <VocabularySuggestions />
      </Box>
      <Box marginTop="30px">
        <AlternateApproach />
      </Box>
    </Box>
  );
};
