import { Box, Link, Text, VStack } from '@chakra-ui/react';
import { Logo } from './Logo';

export const NotFoundPage = () => {
  return (
    <Box
      w="100vw"
      h="100vh"
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack>
        <Logo />
        <Text>
          The Page you are looking for doesn't exist. Please check the url once
          or{' '}
          <Link href="/" textDecoration="underline">
            click here
          </Link>{' '}
          to go to home.
        </Text>
      </VStack>
    </Box>
  );
};
