import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQueryWithAuthorize } from '../auth/fetchBaseQueryWithAuthorize';

export const writingApi = createApi({
  reducerPath: 'writingApi',
  baseQuery: fetchBaseQueryWithAuthorize,
  endpoints: builder => ({
    getWritingQuestion: builder.mutation<
      { response: string; type: string },
      void
    >({
      query: () => ({
        url: '/writing-question',
        method: 'GET'
      })
    }),
    writingReport: builder.mutation<
      { reportId: string },
      { answer: string; question: string; taskType: string; timeTaken: string }
    >({
      query: ({ answer, question, taskType, timeTaken }) => ({
        url: '/writing-report',
        method: 'POST',
        body: { answer, question, taskType, timeTaken }
      })
    }),
    evaluateWriting: builder.mutation< //TODO: obsolete. To change or Remove
      { reportId: string },
      { answer: string; topic: string; }
    >({
      query: ({ answer, topic }) => ({
        url: '/writing-report',
        method: 'POST',
        body: { answer, topic }
      })
    })
  })
});

export const {
  useGetWritingQuestionMutation,
  useWritingReportMutation,
  useEvaluateWritingMutation
} = writingApi;
