import {
  Box,
  Input,
  ListItem,
  UnorderedList,
  Skeleton,
  Text,
  InputGroup,
  InputRightElement,
  VStack,
  HStack,
  Heading
} from '@chakra-ui/react';
import { useState, useCallback } from 'react';
import axios from 'axios';
import { SearchIcon } from '@chakra-ui/icons';

const listStyle = {
  bg: 'brand.background',
  margin: '10px',
  padding: '5px 10px',
  borderRadius: '5px',
  cursor: 'pointer',
  _hover: {
    bg: '#cccccc'
  }
};

export const VocabularyView = () => {
  const [topic, setTopic] = useState('');
  const [isVocabLoading, setIsVocabLoading] = useState(false);
  const [word, setWord] = useState('');
  const [definition, setDefinition] = useState('');
  const beUrl = process.env.REACT_APP_BE_URL || '';
  const [vocabWords, setVocabWords] = useState(
    [] as Array<Record<string, string>> | null
  );
  const getVocabWords = useCallback(() => {
    setWord('');
    setDefinition('');
    setIsVocabLoading(true);
    axios({
      method: 'POST',
      url: `${beUrl}/get-vocabulary-words`,
      data: { topic }
    }).then(function (response) {
      setVocabWords(response?.data?.keywords);
      setIsVocabLoading(false);
    });
  }, [beUrl, topic]);

  return (
    <Box width="100%" height="90vh" alignItems="flex-start">
      <Box width="100%" height="100%" margin="10px auto" padding="50px 40px">
        <VStack>
          <Text fontSize="16px">
            Enter keyword or topic to get vocab words related to it.
          </Text>
          <InputGroup width="40%" minW="200px" marginBottom="15px">
            <Input
              placeholder="Search the topic"
              onChange={e => setTopic(e.target.value)}
              border="1px solid #4985B3"
            />
            <InputRightElement>
              <SearchIcon
                cursor="pointer"
                onClick={getVocabWords}
                color="green.500"
              />
            </InputRightElement>
          </InputGroup>
          {isVocabLoading && (
            <HStack width="50%" p="50px 0px">
              <Skeleton width="50%" height="20px" />
              <Skeleton width="50%" height="20px" />
              <Skeleton width="50%" height="20px" />
              <Skeleton width="50%" height="20px" />
            </HStack>
          )}
        </VStack>
        {vocabWords && !isVocabLoading && (
          <UnorderedList
            fontSize="16px"
            listStyleType="none"
            width="600px"
            margin="25px auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
          >
            {vocabWords?.map((vocabPair, index) => {
              return (
                <>
                  {Object.keys(vocabPair).map(vocabWord => {
                    return (
                      <ListItem
                        onClick={() => {
                          setWord(vocabWord);
                          setDefinition(vocabPair[vocabWord]);
                        }}
                        key={`vocab-${index}`}
                        {...listStyle}
                      >
                        {vocabWord}
                      </ListItem>
                    );
                  })}
                </>
              );
            })}
          </UnorderedList>
        )}
        {word && !isVocabLoading && (
          <Box
            border="1px solid #e0e0e0"
            p="25px"
            margin="25px auto"
            width="700px"
            bg="#f0f0f0"
            borderRadius="8px"
          >
            <VStack>
              <Heading as="h2" mb="15px">
                {word}
              </Heading>
              <Text letterSpacing="1px">{definition}</Text>
            </VStack>
          </Box>
        )}
      </Box>
    </Box>
  );
};
