import { Box, HStack, Text } from '@chakra-ui/react';

export interface ScoreBreakdownProps {
  taskScore?: string;
  cohesionScore?: string;
  lexicalScore?: string;
  grammaticalScore?: string;
}

export const ScoreBreakdown = ({
  taskScore,
  cohesionScore,
  lexicalScore,
  grammaticalScore
}: ScoreBreakdownProps) => {
  return (
    <Box>
      <Text fontWeight="extrabold" fontSize="xl" marginTop="30px">
        <b>Score Breakdown</b>
      </Text>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        marginTop="20px"
      >
        <Text fontWeight="extrabold" color="brand.text">
          Task Achievement
        </Text>
        <Text
          padding="6px 10px"
          borderRadius="5px"
          bg="brand.background"
          fontSize="lg"
          fontWeight="bold"
        >
          {taskScore}
        </Text>
      </HStack>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        marginTop="10px"
      >
        <Text fontWeight="extrabold" color="brand.text">
          Coherence and Cohesion
        </Text>
        <Text
          padding="6px 10px"
          borderRadius="5px"
          bg="brand.background"
          fontSize="lg"
          fontWeight="bold"
        >
          {cohesionScore}
        </Text>
      </HStack>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        marginTop="10px"
      >
        <Text fontWeight="extrabold" color="brand.text">
          Lexical Resource
        </Text>
        <Text
          padding="6px 10px"
          borderRadius="5px"
          bg="brand.background"
          fontSize="lg"
          fontWeight="bold"
        >
          {lexicalScore}
        </Text>
      </HStack>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        marginTop="10px"
      >
        <Text fontWeight="extrabold" color="brand.text">
          Grammatical Range & Accuracy
        </Text>
        <Text
          padding="6px 10px"
          borderRadius="5px"
          bg="brand.background"
          fontSize="lg"
          fontWeight="bold"
        >
          {grammaticalScore}
        </Text>
      </HStack>
    </Box>
  );
};
