import { Box, Text } from '@chakra-ui/react';

export const AlternateApproach = () => {
  return (
    <Box>
      <Text fontWeight="extrabold" fontSize="xl">
        <b>Alternate Approach</b>
      </Text>
      <Text marginTop="10px">
        We have written an alternate answer for you which could score 8+ bands.
        This is in no way suggesting that the answer submitted is good or bad.
        This is just an alternate approach aiming to help you learn better.
      </Text>
      <Box
        marginTop="10px"
        bg="brand.background"
        padding="10px"
        borderRadius="5px"
      >
        <Text>Sample Answer</Text>
        <Text color="brand.text">
          Technology is flourishing by leaps and bounds and providing us with
          new avenues while keeping us updated with the latest news and current
          affairs. Therefore, a fair amount of people believe, a conventional
          way of newspaper reading will be disappeared. I do not completely
          agree with it because a conventional newspaper is the easiest and
          cheapest way to get news. To embark on, there are multiple reasons why
          the traditional ways of getting news are still popular. First of all,
          reading the newspaper has become the ardent habit of many people.
          Everyone whether from the affluent or middle class is seen desperately
          waiting for paper in the morning and enjoying it reading with a cup of
          tea. Moreover, these are the most portable, cheapest, and easiest ways
          of knowing about global activities. It can be carried from one place
          to another in the bag and is available at an economical price. It is
          so handy and merely continuing the flip of pages can make you
          omniscient. Secondly, electricity and other appliances are not
          required. Moreover, the other attachments are like icing on the cake.
          For instance, the Hindustan Times has multiple attachments like
          women’s fashion, career guides, culinary art, and so on. However,
          undoubtedly, technology has given a radical approach to reading news,
          for example, videos provide a full and clear view of reading Besides
          that we can download, share, and forward them to our relatives and
          friends. Needless to say, technophobia will have no place in this
          ever-advanced modern world. To conclude, the lives of people are
          drastically affected by advanced versions of technology yet, in my
          opinion, it will not be able to pose a threat to the existence of
          traditional newspapers and magazines.
        </Text>
      </Box>
    </Box>
  );
};
