import { Box, Stack, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getWordCount } from '../../utils/getWordCount';
import {
  useGetWritingQuestionMutation,
  useWritingReportMutation
} from '../../writingApi';
import { LeftSidebar } from '../LeftSidebar/LeftSidebar';
import { WritingArea } from '../WritingArea/WritingArea';
import { getElapsedTime } from '../../utils/getElapsedTime';

export const WritingView = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');
  const toast = useToast();
  const [getWritingQuestion, { data, isLoading, isSuccess }] =
    useGetWritingQuestionMutation();
  // const [
  //   evaluateWriting,
  //   {
  //     data: writingReportResult,
  //     isSuccess: writingReportResultIsSUccess,
  //     isLoading: writingReportResultIsLoading
  //   }
  // ] = useEvaluateWritingMutation();
  const [
    submitWriting,
    {
      data: writingReportResult,
      isSuccess: isWritingReportSubmitted,
      isLoading: isWritingReportLoading,
      isError: isWritingReportError
    }
  ] = useWritingReportMutation();

  useEffect(() => {
    const timeNow = new Date();
    setStartTime(timeNow.getTime());
    const elapsedTimeIntervalRef = setInterval(() => {
      const [h, m, s] = getElapsedTime(timeNow);
      setHours(h);
      setMinutes(m);
      setSeconds(s);
    }, 1000);
    return () => {
      clearInterval(elapsedTimeIntervalRef);
    };
  }, []);

  useEffect(() => {
    getWritingQuestion();
  }, [getWritingQuestion]);

  useEffect(() => {
    setWordCount(getWordCount(answer));
  }, [answer]);

  useEffect(() => {
    if (isSuccess && data) {
      setQuestion(data?.response);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (
      writingReportResult &&
      writingReportResult.reportId &&
      isWritingReportSubmitted
    ) {
      window.location.href = `/writing-report/${writingReportResult.reportId}`;
    }
  }, [isWritingReportSubmitted, writingReportResult]);

  useEffect(() => {
    if (isWritingReportError && !isWritingReportLoading) {
      toast({
        title: 'Error',
        description: 'We were unable to submit your answer. Please try again!',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }, [isWritingReportError, isWritingReportLoading, toast]);

  const handleSubmit = () => {
    const endTime = new Date().getTime();
    const timeTaken = Math.trunc((endTime - startTime) / 1000);
    submitWriting({
      answer,
      question,
      timeTaken: `${timeTaken}`,
      taskType: 'Task 2'
    });
  };

  return (
    <>
      <Stack
        direction={{ base: 'row', sm: 'column-reverse', md: 'row' }}
        width="100%"
        padding="0px 30px"
        alignItems="flex-start"
        spacing="30px"
      >
        <Box>
          <LeftSidebar
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            handleSubmit={handleSubmit}
            wordCount={wordCount}
            isSubmitting={isWritingReportLoading}
          />
        </Box>
        <Box width="100%">
          <WritingArea
            question={question}
            isLoading={isLoading}
            setAnswer={setAnswer}
          />
        </Box>
      </Stack>
    </>
  );
};
