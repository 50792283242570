import { Box, Button, VStack } from '@chakra-ui/react';
import { Timer } from './Timer';
import { InfoPanel } from './InfoPanel';

export interface LeftSidebarProps {
  wordCount: number;
  handleSubmit: () => void;
  hours: string;
  minutes: string;
  seconds: string;
  isSubmitting: boolean;
}

export const LeftSidebar = ({
  wordCount,
  handleSubmit,
  hours,
  minutes,
  seconds,
  isSubmitting
}: LeftSidebarProps) => {
  return (
    <VStack width="100%" maxWidth="350px" spacing="40px">
      <Box>
        <Timer hours={hours} minutes={minutes} seconds={seconds} />
      </Box>
      <Box>
        <InfoPanel wordCount={wordCount} />
      </Box>
      <Button
        bg="brand.primary"
        _hover={{ bg: 'brand.primary', opacity: '0.9' }}
        fontWeight="bold"
        color="white"
        onClick={handleSubmit}
        isDisabled={isSubmitting || wordCount < 50}
      >
        {isSubmitting
          ? 'Submitting. Please wait...'
          : 'Finish & Submit for evaluation'}
      </Button>
    </VStack>
  );
};
