import {
  Box,
  Divider,
  Text,
  HStack,
  VStack,
  Stack,
  Heading
} from '@chakra-ui/react';
import {
  faHeadphones,
  faPencil,
  faSpellCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../common/Logo';
import { AccountNavigation } from '../../common/AccountNavigation';
import { useCurrentUser } from '../../auth/hooks/useCurrentUser';

const Box_Style = {
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #e0e0e0',
  cursor: 'pointer',
  overflow: 'hidden',
  transition: 'all 0.3s'
};

export const HomeView = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useCurrentUser();
  console.log('>>>>>>>isLoggedIn', isLoggedIn);
  return (
    <>
      <HStack
        minH="100vh"
        width="100%"
        margin="0px auto"
        textAlign="center"
        justifyContent="center"
        fontSize="xl"
        p="20px 15px"
      >
        <VStack>
          <VStack spacing={8}>
            <Heading as="h1" textTransform="uppercase" letterSpacing="1px">
              Prepare for IELTS exam with AI
            </Heading>
            <>
              <Text fontWeight="semibold">
                What you want to practice today?
              </Text>
              <Stack
                direction={['column', 'row']}
                marginTop="-5"
                spacing={10}
                pt={8}
              >
                <Box
                  {...Box_Style}
                  borderColor="#1abc9c"
                  bg="#1abc9c"
                  _hover={{
                    bg: 'white',
                    fontWeight: '600',
                    svg: {
                      color: '#1abc9c'
                    },
                    p: {
                      color: '#1abc9c'
                    }
                  }}
                  onClick={() => navigate('writing')}
                >
                  <Text color="white" fontWeight="semibold">
                    <FontAwesomeIcon color="white" icon={faPencil} size="sm" />{' '}
                    Writing
                  </Text>
                </Box>
                <Box
                  {...Box_Style}
                  borderColor="#f1c40f"
                  onClick={() => navigate('vocabulary')}
                  bg="#f1c40f"
                  _hover={{
                    bg: 'white',
                    fontWeight: '600',
                    svg: {
                      color: '#f1c40f'
                    },
                    p: {
                      color: '#f1c40f'
                    }
                  }}
                >
                  <Text color="white" fontWeight="semibold">
                    <FontAwesomeIcon
                      color="white"
                      icon={faSpellCheck}
                      size="sm"
                    />{' '}
                    Vocabulary
                  </Text>
                </Box>
                <Box
                  {...Box_Style}
                  borderColor="#ff64c3"
                  // onClick={() => navigate('vocabulary')}
                  bg="#ff64c3"
                  _hover={{
                    bg: 'white',
                    fontWeight: '600',
                    svg: {
                      color: '#ff64c3'
                    },
                    p: {
                      color: '#ff64c3'
                    }
                  }}
                >
                  <Text color="white" fontWeight="semibold">
                    <FontAwesomeIcon
                      color="white"
                      icon={faHeadphones}
                      size="sm"
                    />{' '}
                    Speaking
                    <Text fontSize="sm">Coming Soon</Text>
                  </Text>
                </Box>
                {/* <Box {...Box_Style} onClick={handleReading}>
          <Text>Reading</Text>
          <FontAwesomeIcon icon={faBookOpenReader} size="2xl" />
        </Box> */}
              </Stack>
            </>
          </VStack>
          <Box marginTop="20px">
            <Divider marginBottom="20px" />
            <Heading as="h2" size="lg">
              Why Club IELTS?
            </Heading>
            <Text marginTop="10px">
              Club IELTS is an AI powered tool that gives writing evaluation
              results instantly.
            </Text>
            <Text marginTop="5px">
              Yes, you heard that right. Try our writing tool above to see it
              for yourself.
            </Text>
          </Box>
          <Text pt={8} fontSize="16px" lineHeight="24px" letterSpacing=".5px">
            <Divider marginBottom="20px" />
            Introducing "Club IELTS" – Your AI-Powered IELTS Writing Coach! Club
            IELTS is an innovative app that utilizes AI to assess students'
            writing skills instantly and provide personalized feedback and band
            scores. With a focus on improving vocabulary and coherence, the app
            offers a wide range of writing exercises for practice. Keep track of
            your progress through performance analytics and let Club IELTS guide
            you on your journey to IELTS success. Try the app now and join the
            club of high-achieving IELTS test-takers!
          </Text>
          <Text pt={8} fontSize="16px" lineHeight="24px" letterSpacing=".5px">
            Enhance your writing, expand your vocabulary, and boost your reading
            comprehension with the power of AI. Our app provides personalized
            feedback and targeted exercises to help you master the art of
            written communication.
          </Text>
          <Box marginTop="20px">
            <Divider marginBottom="20px" />
            <Text fontSize="sm">&copy; Copyright 2023 Club IELTS</Text>
          </Box>
        </VStack>
      </HStack>
    </>
  );
};
