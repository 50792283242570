import { NavigationLayout } from '../../common/Layouts/NavigationLayout';
import { WritingView } from '../components/WritingView/WritingView';
import { WritingAuthGateway } from './WritingAuthGateway';

export const Writing = () => {
  return (
    <WritingAuthGateway>
      <NavigationLayout>
        <WritingView />
      </NavigationLayout>
    </WritingAuthGateway>
  );
};
