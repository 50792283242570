import { useLoginMutation } from '../authApi';
import { useEffect, useState } from 'react';

export const useLogin = () => {
  const [login, { isLoading, isSuccess, isError, data, error }] =
    useLoginMutation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInvalidUsernamePassword, setIsInvalidUsernamePassword] =
    useState(false);
  const [isUnknownError, setIsUnknownError] = useState(false);

  useEffect(() => {
    if (isSuccess && !!data && !isError) {
      const { token, role } = data;
      if (token) {
        setIsLoggedIn(true);
        localStorage.setItem('ciToken', token);
        localStorage.setItem('role', role);
      }
    }
    if (isError && !!error && !isSuccess) {
      if ('status' in error && error?.status === 401) {
        setIsInvalidUsernamePassword(true);
        setIsUnknownError(false);
      } else {
        setIsInvalidUsernamePassword(false);
        setIsUnknownError(true);
      }
    }
  }, [data, error, isError, isSuccess]);

  const userLogin = (username: string, password: string) => {
    login({ username, password });
  };
  return {
    userLogin,
    isLoading,
    isLoggedIn,
    isInvalidUsernamePassword,
    isUnknownError
  };
};
