import { Box, HStack, Text } from '@chakra-ui/react';

export const WordReplacements = () => {
  return (
    <Box>
      <Text fontWeight="extrabold" fontSize="xl">
        <b>Word Replacements</b>
      </Text>
      <Text marginTop="10px">
        This section highlights words or sentences from your answer which could
        be replaced with better words for higher score.
      </Text>
      <HStack spacing="10px" marginTop="10px">
        <Text
          background="brand.background"
          padding="5px 10px"
          borderRadius="5px"
        >
          abc {'->'} ser
        </Text>
        <Text
          background="brand.background"
          padding="5px 10px"
          borderRadius="5px"
        >
          abc {'->'} ser
        </Text>
      </HStack>
    </Box>
  );
};
