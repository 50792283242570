import { configureStore } from '@reduxjs/toolkit';
import { authApi } from '../features/auth/authApi';
import { usersApi } from '../features/auth/usersApi';
import { writingApi } from '../features/writing/writingApi';
import { writingReportApi } from '../features/WritingReport/writingReportApi';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [writingApi.reducerPath]: writingApi.reducer,
    [writingReportApi.reducerPath]: writingReportApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      usersApi.middleware,
      writingApi.middleware,
      writingReportApi.middleware
    )
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
