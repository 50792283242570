import { useState, useEffect } from 'react';
import { useCurrentUserQuery } from '../usersApi';
import { USER_ROLES } from '../constants';
import { isAuthTokenPresent } from '../utils/isAuthTokenPresent';
export const useCurrentUser = () => {
  const { data, isLoading } = useCurrentUserQuery(undefined, {
    skip: !isAuthTokenPresent()
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      if (data) {
        setIsLoggedIn(true);
      }
    }
  }, [data, isLoading]);
  return {
    isLoggedIn,
    isLoading,
    user: data,
    isAdmin: data?.role === USER_ROLES.admin,
    isTeacher: data?.role === USER_ROLES.teacher,
    isInstitute: data?.role === USER_ROLES.institute,
    isUser: data?.role === USER_ROLES.user
  };
};
