import { Box, Heading, Text, Textarea, VStack } from '@chakra-ui/react';

export interface WritingAreaProps {
  setAnswer: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  question: string;
}

export const WritingArea = ({
  setAnswer,
  isLoading,
  question
}: WritingAreaProps) => {
  return (
    <VStack spacing="15px" width="100%" padding="0px 40px" alignItems="left">
      <Heading as="h1">IELTS Writing Practice</Heading>
      <Text fontWeight="bold">
        {question ||
          'Placeholder question: Write a letter to your friend who is moving to a new home'}
      </Text>
      <Box width="100%">
        <Textarea
          height="100vh"
          maxH="350px"
          placeholder="Write an eassy with at least 150 words or more..."
          resize="none"
          bgColor="brand.background"
          fontWeight="bold"
          onChange={e => {
            setAnswer(e.target.value);
          }}
          disabled={isLoading}
        />
      </Box>
    </VStack>
  );
};
