import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';

export const DetailedFeedback = () => {
  return (
    <Box>
      <HStack justifyContent="space-between">
        <Text fontWeight="extrabold" fontSize="xl">
          <b>Detailed Feedback</b>
        </Text>
        <Button
          bg="brand.primary"
          _hover={{
            bg: 'brand.primary',
            opacity: 0.8
          }}
          color="white"
          size="sm"
        >
          View Detailed Feedback in Punjabi
        </Button>
      </HStack>
      <Stack
        direction={{ base: 'row', sm: 'column', md: 'row' }}
        marginTop="10px"
      >
        <Box padding="20px" border="0.5px solid" borderRadius="5px">
          <Text fontWeight="bold">Task Achievement</Text>
          <Text marginTop="10px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industrys standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Text>
        </Box>
        <Box padding="20px" border="0.5px solid" borderRadius="5px">
          <Text fontWeight="bold">Coherence & Cohesion</Text>
          <Text marginTop="10px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industrys standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Text>
        </Box>
      </Stack>
      <Stack
        direction={{ base: 'row', sm: 'column', md: 'row' }}
        marginTop="10px"
      >
        <Box padding="20px" border="0.5px solid" borderRadius="5px">
          <Text fontWeight="bold">Lexical Resource</Text>
          <Text marginTop="10px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industrys standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Text>
        </Box>
        <Box padding="20px" border="0.5px solid" borderRadius="5px">
          <Text fontWeight="bold">Grammatical Range & Accuracy</Text>
          <Text marginTop="10px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industrys standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};
