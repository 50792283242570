import { Box, HStack, Text, VStack } from '@chakra-ui/react';

export interface TimerProps {
  hours: string;
  minutes: string;
  seconds: string;
}

export const Timer = ({ hours, minutes, seconds }: TimerProps) => {
  return (
    <HStack spacing="10px">
      <VStack>
        <Box bg="brand.background" borderRadius="10px">
          <Text fontSize="3xl" padding="10px 20px">
            {hours}
          </Text>
        </Box>
        <Box>
          <Text fontWeight="bold">Hours</Text>
        </Box>
      </VStack>
      <VStack>
        <Box bg="brand.background" borderRadius="10px">
          <Text fontSize="3xl" padding="10px 20px">
            {minutes}
          </Text>
        </Box>
        <Box>
          <Text fontWeight="bold">Minutes</Text>
        </Box>
      </VStack>
      <VStack>
        <Box bg="brand.background" borderRadius="10px">
          <Text fontSize="3xl" padding="10px 20px">
            {seconds}
          </Text>
        </Box>
        <Box>
          <Text fontWeight="bold">Seconds</Text>
        </Box>
      </VStack>
    </HStack>
  );
};
