import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export const fetchBaseQueryWithAuthorize = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BE_URL,
  prepareHeaders: headers => {
    const token = localStorage.getItem('ciToken');

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});
