import { Box, Text, VStack } from '@chakra-ui/react';
import { Logo } from './Logo';

export const Loading = () => {
  return (
    <Box
      w="100vw"
      h="100vh"
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack>
        <Logo />
        <Text>Loading...</Text>
      </VStack>
    </Box>
  );
};
