import { ALL_USER_ROLES } from '../../auth/constants';
import { useLoginRedirect } from '../../auth/hooks/useLoginRedirect';
import { Loading } from '../../common/Loading';

export type VocabularyAuthGatewayProps = React.PropsWithChildren;

export const VocabularyAuthGateway = ({
  children
}: {
  children: JSX.Element;
}) => {
  const { isLoading, isLoggedIn } = useLoginRedirect('/login', ALL_USER_ROLES);
  if (isLoading || !isLoggedIn) {
    return <Loading />;
  }
  return isLoggedIn && <>{children}</>;
};
