import { NavigationLayout } from '../../common/Layouts/NavigationLayout';
import { VocabularyView } from '../components/VocabularyView/VocabularyView';
import { VocabularyAuthGateway } from './VocabularyAuthGateway';

export const Vocabulary = () => {
  return (
    <VocabularyAuthGateway>
      <NavigationLayout>
        <VocabularyView />
      </NavigationLayout>
    </VocabularyAuthGateway>
  );
};
