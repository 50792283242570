import { Box, HStack, Text } from '@chakra-ui/react';

export const VocabularySuggestions = () => {
  return (
    <Box>
      <Text fontWeight="extrabold" fontSize="xl">
        <b>Vocabulary Jam</b>
      </Text>
      <Text marginTop="10px">
        Some word suggestions related to the topic with meanings to improve your
        vocabulary.
      </Text>
      <HStack spacing="10px" marginTop="10px">
        <Text
          background="brand.background"
          padding="5px 10px"
          borderRadius="5px"
        >
          tumid
        </Text>
        <Text>adj. abnormally distended especially by fluids or gas</Text>
      </HStack>
      <HStack spacing="10px" marginTop="10px">
        <Text
          background="brand.background"
          padding="5px 10px"
          borderRadius="5px"
        >
          tumid
        </Text>
        <Text>adj. abnormally distended especially by fluids or gas</Text>
      </HStack>
    </Box>
  );
};
