import { Text, HStack, Button } from '@chakra-ui/react';
import { FaPencilAlt } from 'react-icons/fa';
import { SubmittedAnswerModal } from './SubmittedAnswerModal';
import { useState } from 'react';

export const SubmittedAnswer = ({ answer }: { answer?: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <HStack justifyContent="space-between">
        <HStack>
          <FaPencilAlt size={15} />
          <Text fontWeight="bold">Submitted Answer</Text>
        </HStack>
        <Button
          size="sm"
          bg="brand.primary"
          _hover={{ bg: 'brand.primary', opacity: 0.8 }}
          color="white"
          onClick={() => setIsOpen(true)}
        >
          View your Answer
        </Button>
      </HStack>
      <SubmittedAnswerModal
        answer={answer}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
