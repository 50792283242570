import { Box, Heading, Skeleton, Text, VStack } from '@chakra-ui/react';
import { Logo } from '../../common/Logo';
import { getRandomNumber } from '../utils/getRandomNumber';
import { useEffect } from 'react';

const TIPS_LIST = [
  'Conclusion – A solid conclusion can take you a long way. It is particularly vital for Task 2. Round up your points and back whatever you have expressed in the body paragraphs. Go back to the prompt and solidify your answer.',
  'Introduction – Introduce the topic / prompt appropriately. Lead on to the body paragraphs clearly and do not waste time polishing your introduction.',
  'Body Paragraphs 1 and 2 – These paragraphs will help you break your ideas down in a clear manner. You need to elaborate upon your ideas with relevant, sound examples. It is generally good to stick to 1 idea per paragraph. Remember, you need to transition from 1 paragraph to the other in a logical, seamless manner. Make sure they are connected properly and sensibly.'
];

const REFRESH_INTERVAL_IN_MS = 10000;

export const ReportNotEvaluated = () => {
  useEffect(() => {
    const interval = setInterval(
      () => window.location.reload(),
      REFRESH_INTERVAL_IN_MS
    );
    return () => clearInterval(interval);
  }, []);
  return (
    <VStack p="50px" spacing="20px" justifyContent="center">
      <Logo />
      <Skeleton
        startColor="pink.500"
        endColor="orange.500"
        w="60%"
        height="20px"
      />
      <Heading fontSize="2xl" textAlign="center">
        Our AI minds are evaluating your report. Please wait few seconds or
        check back in sometime...
      </Heading>
      <Box>
        <Heading
          p="5px"
          bgColor="brand.background"
          fontSize="xl"
          textAlign="center"
          borderRadius="40px"
          mt="50px"
        >
          Feed for brain
        </Heading>
        <Text color="brand.text" mt="5px">
          {TIPS_LIST[getRandomNumber(0, 2)]}
        </Text>
      </Box>
    </VStack>
  );
};
