import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQueryWithAuthorize } from '../auth/fetchBaseQueryWithAuthorize';

export interface WritingReport {
  answer: string;
  cohesionEvaluation: string;
  cohesionScore: string;
  createdAt: string;
  overallScore: string;
  grammaticalEvaluation: string;
  grammaticalScore: string;
  taskType: string;
  isEvaluated: boolean;
  lexicalEvaluation: string;
  lexicalScore: string;
  question: string;
  reportId: string;
  taskEvaluation: string;
  taskScore: string;
  timeTaken: string;
  updatedAt: string;
  userId: string;
  vocabWords: Array<any>;
  wordReplacements: Array<any>;
}

export const writingReportApi = createApi({
  reducerPath: 'writingReportApi',
  baseQuery: fetchBaseQueryWithAuthorize,
  endpoints: builder => ({
    getWritingReportStatus: builder.query<
      { isEvaluated: boolean },
      { reportId: string }
    >({
      query: ({ reportId }) => ({
        url: `/writing-report-status/${reportId}`,
        method: 'GET'
      })
    }),
    getWritingReport: builder.query<WritingReport, { reportId: string }>({
      query: ({ reportId }) => ({
        url: `/writing-report/${reportId}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetWritingReportStatusQuery, useGetWritingReportQuery } =
  writingReportApi;
