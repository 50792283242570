import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BE_URL }),
  endpoints: builder => ({
    login: builder.mutation<
      { token: string; role: string },
      { username: string; password: string }
    >({
      query: ({ username, password }) => ({
        url: '/login',
        method: 'POST',
        body: { username, password }
      })
    })
  })
});

export const { useLoginMutation } = authApi;
