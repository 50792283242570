import { HStack, Box, Text, VStack } from '@chakra-ui/react';
import { FiPenTool } from 'react-icons/fi';

export interface InfoPanelProps {
  wordCount: number;
}

export const InfoPanel = ({ wordCount }: InfoPanelProps) => {
  return (
    <VStack spacing="20px">
      <HStack width="100%" spacing="15px">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bg="brand.background"
          borderRadius="10px"
          w="50px"
          h="50px"
        >
          <FiPenTool size={30} />
        </Box>
        <VStack alignItems="left" spacing="0px">
          <Text fontWeight="bold">Write atleast 150 words</Text>
          <Text color="brand.primary" opacity="0.7">
            You should write atleast 150 words
          </Text>
        </VStack>
      </HStack>
      <HStack width="100%" spacing="15px">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bg="brand.background"
          borderRadius="10px"
          w="50px"
          h="50px"
        >
          <FiPenTool size={30} />
        </Box>
        <VStack alignItems="left" spacing="0px">
          <Text fontWeight="bold">Word Count</Text>
          <Text color="brand.primary" opacity="0.7">
            {wordCount}/150
          </Text>
        </VStack>
      </HStack>
    </VStack>
  );
};
