import { NavigationLayout } from '../../common/Layouts/NavigationLayout';
import { HomeView } from '../components/HomeView';

export const Home = () => {
  return (
    <>
      <NavigationLayout>
        <HomeView />
      </NavigationLayout>
    </>
  );
};
