import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQueryWithAuthorize } from './fetchBaseQueryWithAuthorize';

export interface User {
  name: string;
  username: string;
  createdAt: Date;
  updatedAt: Date;
  role: string;
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQueryWithAuthorize,
  endpoints: builder => ({
    currentUser: builder.query<User, void>({
      query: () => ({
        url: '/me',
        method: 'GET'
      })
    })
  })
});

export const { useCurrentUserQuery } = usersApi;
