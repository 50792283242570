import { Image } from '@chakra-ui/react';

export const Logo = ({ width }: { width?: number }) => {
  return (
    <Image
      src="/club-ielts-logo.png"
      alt="Club IELTS Logo"
      loading="lazy"
      w={width ? `${width}px` : '150px'}
    />
  );
};
