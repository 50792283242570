import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Button
} from '@chakra-ui/react';

export interface SubmittedAnswerModalProps
  extends Omit<ModalProps, 'children'> {
  answer?: string;
}

export const SubmittedAnswerModal = ({
  isOpen,
  onClose,
  answer
}: SubmittedAnswerModalProps) => {
  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Submitted Answer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{answer || ''}</ModalBody>

        <ModalFooter>
          <Button
            size="sm"
            bg="brand.primary"
            _hover={{ bg: 'brand.primary', opacity: 0.8 }}
            color="white"
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
