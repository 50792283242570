import { useParams } from 'react-router-dom';
import { NavigationLayout } from '../../common/Layouts/NavigationLayout';
import { WritingReportView } from '../components/WritingReportView';
import { WritingReportContext } from '../context/WritingReportContext';

export const WritingReport = () => {
  const { reportId } = useParams();
  return (
    <NavigationLayout>
      <WritingReportContext.Provider value={{ reportId: reportId || '' }}>
        <WritingReportView />
      </WritingReportContext.Provider>
    </NavigationLayout>
  );
};
