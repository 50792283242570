import { Box, Text } from '@chakra-ui/react';

export const Footer = () => {
  const date = new Date();
  const year = date.getUTCFullYear();
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Text>Copyright &copy; {year} Club IELTS</Text>
    </Box>
  );
};
