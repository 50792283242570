import {
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery
} from '@chakra-ui/react';
import { Logo } from '../Logo';
import { useNavigate } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { Footer } from '../Footer';
import { FiMenu } from 'react-icons/fi';
import { HamburgerIcon } from '@chakra-ui/icons';

const NAV_ITEMS = [
  { label: 'Home', link: '/', isActionButton: false, isHighlightedLink: false },
  {
    label: 'Writing',
    link: '/writing',
    isActionButton: false,
    isHighlightedLink: false
  },
  {
    label: 'Vocabulary',
    link: '/vocabulary',
    isActionButton: false,
    isHighlightedLink: false
  },
  {
    label: 'Log in',
    link: '/login',
    isActionButton: false,
    isHighlightedLink: true
  },
  {
    label: 'Sign up for free',
    link: '/signup',
    isActionButton: true,
    isHighlightedLink: false
  }
];

export interface NavigationLayoutProps extends PropsWithChildren {}

export const NavigationLayout = ({ children }: NavigationLayoutProps) => {
  const navigate = useNavigate();
  const [isTablet] = useMediaQuery('(max-width: 800px)');
  return (
    <Box p="0px 20px">
      <HStack
        width="100%"
        margin="0px auto"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        fontSize="xl"
        p="20px 15px"
        marginBottom="20px"
      >
        <Box padding="0px 20px">
          <Logo width={80} />
        </Box>
        {!isTablet && (
          <HStack
            spacing={'20px'}
            justifyContent="flex-end"
            width="100%"
            paddingRight="20px"
            flexWrap="wrap"
          >
            {NAV_ITEMS.map((navItem, index) => (
              <Button
                key={`primaryMenuItem_${index}`}
                bg={navItem.isActionButton ? 'brand.primary' : 'none'}
                color={
                  navItem.isActionButton
                    ? 'white'
                    : navItem.isHighlightedLink
                    ? 'brand.primary'
                    : 'black'
                }
                _hover={{ background: 'grey.100', color: 'brand.primary' }}
                onClick={() => {
                  navigate(navItem.link);
                }}
              >
                {navItem.label}
              </Button>
            ))}
          </HStack>
        )}
        {isTablet && (
          <HStack
            justifyContent="flex-end"
            width="100%"
            paddingRight="20px"
            flexWrap="wrap"
          >
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                variant="outline"
                color="brand.primary"
              />
              <MenuList>
                {NAV_ITEMS.map((navItem, index) => (
                  <MenuItem
                    bg={navItem.isActionButton ? 'brand.primary' : 'none'}
                    color={
                      navItem.isActionButton
                        ? 'white'
                        : navItem.isHighlightedLink
                        ? 'brand.primary'
                        : 'black'
                    }
                    onClick={() => {
                      navigate(navItem.link);
                    }}
                    key={`primaryMobileMenuItem_${index}`}
                  >
                    {navItem.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
        )}
      </HStack>
      {children}
      <Box fontSize="sm" marginTop="50px" marginBottom="20px">
        <Footer />
      </Box>
    </Box>
  );
};
