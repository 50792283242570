import { createBrowserRouter } from 'react-router-dom';
import { Home } from '../features/home/pages/Home';
import { Writing } from '../features/writing/pages/Writings';
import { Vocabulary } from '../features/vocabulary/pages/Vocabulary';
import { Login } from '../features/login/pages/Login';
import { SignUp } from '../features/signup/pages/SignUp';
import { NotFoundPage } from '../features/common/NotFoundPage';
import { WritingReport } from '../features/WritingReport/pages/WritingReport';

export const router = createBrowserRouter([
  {
    path: '*',
    element: <NotFoundPage />
  },
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/writing',
    element: <Writing />
  },
  {
    path: '/vocabulary',
    element: <Vocabulary />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/signup',
    element: <SignUp />
  },
  {
    path: '/writing-report/:reportId',
    element: <WritingReport />
  }
]);
