import { useState, useEffect } from 'react';
import { useCurrentUserQuery } from '../usersApi';
import { useLogout } from './useLogout';
export const useLoginRedirect = (
  redirectTo = '/',
  requiredRoles = ['user']
) => {
  const { data, isLoading } = useCurrentUserQuery();
  const logout = useLogout();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      if (data && requiredRoles.includes(data.role)) {
        setIsLoggedIn(true);
      } else {
        logout(redirectTo);
      }
    }
  }, [data, isLoading, logout, redirectTo, requiredRoles]);
  return { isLoggedIn, isLoading };
};
